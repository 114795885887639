#inspirations {
  .slugName {
    span {
      display: inline-block;
      width: auto;
      float: left;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
    }

    input#post_name {
      border: none;
      font-size: 12px;
      line-height: 20px;
      height: 18px;
      display: inline-block;
      width: auto;
      float: left;
      box-shadow: none;
      padding-left: 0;
      color: #0a6aa1;
      font-weight: bold;
      min-width: 400px;
    }
  }

  .inspirationsPageForm {
    .form-group {
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  a.showImage {
    display: inline-block;
    position: relative;

    span.removeImage {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 25px;
      color: #7a0026;
      cursor: pointer;
    }
  }
}




