.table-orders {
  th {
    text-align: center;
  }
  .row-order {
    .id { font-weight: bold; }
    .price {
      text-align: center;
      * { white-space: nowrap; }
      *:before { content: '$'; }
      del { color: darkred; }
      ins { color: darkgreen; text-decoration: none; }
    }
    .quantity{
      text-align: center;
    }
    .address {
    }
    .date { text-align: center; }
    .actions { width: 15em; padding-left:10px; padding-right: 10px; }
  }
}

.table-order-details{
    th{
      text-align:right;
      width: 15%;
    }
    td{
      text-align: left;
    }
}

.shipping-row {
    td {
      border-top-width: 2px !important;
    }
}

table.table-centered tr th, table.table-centered tr td {
  text-align: center !important;
}

.inline-left {
  display: inline-block;
  text-align: left;
}
