.table-single_pages {
  .row-single_pages {

  }
}

.formSinglePages, .inspirationsPageTypeItemForm {
  .form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }

  .productsContainer {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 15px 0;
    margin-bottom: 20px;
  }

  .menuContainer {
    @extend .productsContainer;
  }
}

#productsModal {
  @media (min-width: 768px) {
    .modal-dialog {
      width: 65%;
      margin: 30px auto;
    }
  }
}

.singlePageForm, .inspirationsPageTypeItemForm {
  .productsContainer {
    .productsList {
      ul {
        list-style: none;
        display: inline-block;
        width: 100%;
        li {
          display: inline-block;
          width: 11%;
          margin: 1% 1% 1% 0;
          float: left;
          height: 250px;
          border: 1px solid #eee;
          position: relative;
          a.removeRow {
            display: inline-block;
            position: absolute;
            top: 5px;
            right: 5px;
          }
          img {
            display: inline-block;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
          .imageRowContent {
            margin: 0 5px;
          }
        }
      }
    }
  }
  .jsTree {
    margin-top: 10px;
  }

  .slugName {
    span {
      display: inline-block;
      width: auto;
      float: left;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
    }

    input#post_name {
      border: none;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
      display: inline-block;
      width: auto;
      float: left;
      box-shadow:none;
      padding-left: 0;
      color: #0a6aa1;
      font-weight: bold;
      min-width: 400px;
    }
  }
}




