.currentRow {
  background: #add8e6 !important;
}

.btn-gray {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-gray:hover {
  color: #fff;
  background-color: #868686;
  border-color: #868686;
}

.modaOverlaylLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 0;
  left: 0;
}

.modaOverlaylLoader.lightBackground {
  background: rgba(204, 204, 204, 0.2);
}

.modaOverlaylLoader:after {
  content: url("../img/ajax-loader.gif");
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 12;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%); /* IE 9 */
  -webkit-transform: translate(-50%, -50%); /* Safari */
  transform: translate(-50%, -50%);
}

.table-reviews {

  tbody {
    tr {
      &:first-of-type{
        td {
          border: none !important;
        }
      }
      td {
        &.actions {
          word-wrap: break-word;
        }
        &.review_text {
          word-wrap: break-word;
        }
        ul.rating {
          li {
            padding: 0;

            i.fa-star {
              color: #555555;
              &.selected {
                color: #7a0026 !important;
              }
            }
          }
        }
      }
    }
  }
}