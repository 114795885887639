@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
body {
  padding-bottom: 15px;
}

.page-header:before, .page-header:after {
  content: " ";
  display: table;
}

.page-header:after {
  clear: both;
}

.page-header aside {
  float: right;
}

.btn-group {
  padding-bottom: 25px;
}

.form-inline .form-group {
  height: 60px;
}

.table tr.summary td {
  border-top: 0;
}

.search-orders {
  padding-bottom: 25px;
}

body.not-logged-in {
  padding: 0;
  margin: 0;
  background-color: #fff;
}

.table-products th, .table-categories th {
  text-align: center;
}

.table-products tr td, .table-categories tr td {
  vertical-align: middle;
}

.table-products tr .id, .table-categories tr .id {
  font-weight: bold;
  text-align: right;
}

.table-products tr .sku, .table-categories tr .sku {
  font-weight: bold;
}

.table-products tr .stock, .table-categories tr .stock {
  text-align: center;
}

.table-products tr .stock-instock, .table-categories tr .stock-instock {
  color: darkgreen;
}

.table-products tr .stock-nostock, .table-categories tr .stock-nostock {
  color: darkred;
}

.table-products tr .price, .table-categories tr .price {
  text-align: right;
}

.table-products tr .price *, .table-categories tr .price * {
  white-space: nowrap;
}

.table-products tr .price *:before, .table-categories tr .price *:before {
  content: '$';
}

.table-products tr .price del, .table-categories tr .price del {
  color: darkred;
}

.table-products tr .price ins, .table-categories tr .price ins {
  color: darkgreen;
  text-decoration: none;
}

.table-products tr .post_date, .table-categories tr .post_date {
  text-align: center;
}

.table-products tr .actions, .table-categories tr .actions {
  width: 11em;
}

.table-products tr .quantity, .table-categories tr .quantity {
  text-align: center;
}

.table-products .form-group, .table-categories .form-group {
  position: relative;
  display: inline-block;
  width: 100%;
}

.product-details .product-img {
  margin-bottom: 10px;
}

label.required:after {
  content: ' *';
  color: #880000;
  font-weight: bold;
}

.formHideFix {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100px;
  height: 30px !important;
  padding: 0;
  margin: 0;
}

textarea.tinymce {
  display: inline !important;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
}

.marginTop10 {
  margin-top: 10px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

#attributesRows {
  margin-top: 15px;
}

#attributesRows .select2-container {
  width: 100% !important;
}

.red {
  color: #880000;
}

#attributesRows .form-group {
  display: inline-block;
  width: 100%;
}

div[data-attribute-id] {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

#variationsRows {
  margin-top: 15px;
}

.marginLeftNone {
  margin-left: 0 !important;
}

.tableVariations {
  display: inline-block;
  max-height: 450px !important;
  overflow: auto;
  width: 100%;
}

.searchForm .form-group {
  height: auto;
  position: relative;
}

@media (min-width: 768px) {
  .searchForm .form-group {
    width: 32%;
  }
}

@media (min-width: 768px) and (min-width: 1100px) {
  .searchForm .form-group {
    width: 16%;
  }
}

@media (min-width: 768px) {
  .searchForm .form-group input, .searchForm .form-group select, .searchForm .form-group span.select2-container {
    display: block;
    width: 100% !important;
  }
}

.searchForm .form-group .clearInput {
  position: absolute;
  top: 8px;
  right: 5px;
  color: #880000;
}

.searchForm .form-group .clearSelect {
  position: absolute;
  top: 2px;
  right: 5px;
  color: #880000;
}

.imageContainer {
  position: relative;
  display: inline-block;
  width: auto;
  margin-top: 10px;
}

.imageContainer img {
  display: inline-block;
  max-height: 240px;
}

.imageContainer:first-child img {
  background-color: #faebcc;
  border-color: #e3d7c1;
}

.imageContainer .removeImage {
  display: inline-block;
  color: #ccc;
  position: absolute;
  top: 0px;
  right: 5px;
}

.imageContainer .removeImage:hover {
  color: #fff;
}

.inline {
  display: inline-block !important;
}

.marginNone {
  margin: 0 !important;
}

.paddingLeftNone {
  padding-left: 0 !important;
}

.tab-content > .tab-pane {
  display: block;
  opacity: 0;
  position: absolute;
  left: -100000px;
}

.tab-content > .active {
  opacity: 1;
  display: block;
  position: relative;
  left: 0;
}

.select2-container .select2-selection--multiple, .select2-container .select2-selection--single {
  min-height: 32px;
  border-color: #ccc;
}

.width100 {
  display: inline-block !important;
  width: 100% !important;
}

.table-categories th {
  text-align: left;
}

.table-categories th.thumb {
  min-width: 50px;
}

@media (min-width: 520px) {
  .table-categories th.thumb {
    min-width: 100px;
  }
}

.table-categories th.text {
  width: 90%;
}

.table-categories th.actions {
  min-width: 10em;
}

.table-categories tr td.thumb {
  text-align: center;
}

.table-categories tr td.thumb img {
  display: inline;
  max-height: 33px;
}

@media (min-width: 520px) {
  .table-categories tr td.thumb img {
    max-height: 66px;
  }
}

.table-categories tr.depth-0 td.text {
  font-weight: bold;
  font-size: 120%;
}

.table-categories tr:not(.depth-0):not(.depth-1) td.text {
  font-style: italic;
}

.table-orders th {
  text-align: center;
}

.table-orders .row-order .id {
  font-weight: bold;
}

.table-orders .row-order .price {
  text-align: center;
}

.table-orders .row-order .price * {
  white-space: nowrap;
}

.table-orders .row-order .price *:before {
  content: '$';
}

.table-orders .row-order .price del {
  color: darkred;
}

.table-orders .row-order .price ins {
  color: darkgreen;
  text-decoration: none;
}

.table-orders .row-order .quantity {
  text-align: center;
}

.table-orders .row-order .date {
  text-align: center;
}

.table-orders .row-order .actions {
  width: 15em;
  padding-left: 10px;
  padding-right: 10px;
}

.table-order-details th {
  text-align: right;
  width: 15%;
}

.table-order-details td {
  text-align: left;
}

.shipping-row td {
  border-top-width: 2px !important;
}

table.table-centered tr th, table.table-centered tr td {
  text-align: center !important;
}

.inline-left {
  display: inline-block;
  text-align: left;
}

.table-customers .row-customers .id {
  font-weight: bold;
  text-align: right;
}

.table-customers .row-customers .type, .table-customers .row-customers .name {
  text-align: center;
}

.table-customers .row-customers .actions {
  width: 8em;
}

.table-sales-reps th {
  text-align: left;
}

.table-sales-reps .row-product td {
  vertical-align: middle;
}

.table-sales-reps .row-product .id {
  font-weight: bold;
  text-align: left;
}

.table-sales-reps .row-product .zip {
  font-weight: bold;
}

.table-sales-reps .row-product .rep_code {
  text-align: left;
}

.table-sales-reps .row-product .rep_account {
  color: darkgreen;
}

.table-sales-reps .row-product .created_at, .table-sales-reps .row-product .updated_at {
  text-align: left;
}

.table-sales-reps .row-product .actions {
  width: 8em;
}

.currentRow {
  background: #add8e6 !important;
}

.btn-gray {
  color: #fff;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-gray:hover {
  color: #fff;
  background-color: #868686;
  border-color: #868686;
}

.modaOverlaylLoader {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 0;
  left: 0;
}

.modaOverlaylLoader.lightBackground {
  background: rgba(204, 204, 204, 0.2);
}

.modaOverlaylLoader:after {
  content: url("../img/ajax-loader.gif");
  display: inline-block;
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 12;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  /* IE 9 */
  -webkit-transform: translate(-50%, -50%);
  /* Safari */
  transform: translate(-50%, -50%);
}

.table-reviews tbody tr:first-of-type td {
  border: none !important;
}

.table-reviews tbody tr td.actions {
  word-wrap: break-word;
}

.table-reviews tbody tr td.review_text {
  word-wrap: break-word;
}

.table-reviews tbody tr td ul.rating li {
  padding: 0;
}

.table-reviews tbody tr td ul.rating li i.fa-star {
  color: #555555;
}

.table-reviews tbody tr td ul.rating li i.fa-star.selected {
  color: #7a0026 !important;
}

.table-faq tbody tr:first-of-type td {
  border: none !important;
}

.table-faq tbody tr td.actions, .table-faq tbody tr th.actions {
  word-wrap: break-word;
}

.table-faq tbody tr td.comment, .table-faq tbody tr th.comment {
  word-wrap: break-word;
  width: 55%;
}

.table-designers .row-designers.bg-danger {
  background-color: #f2dede !important;
}

.formDesigner .form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.table-designers_posts .row-designers_posts.bg-danger {
  background-color: #f2dede !important;
}

.formDesignerPost .form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.formSinglePages .form-group, .inspirationsPageTypeItemForm .form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.formSinglePages .productsContainer, .formSinglePages .menuContainer, .inspirationsPageTypeItemForm .productsContainer, .inspirationsPageTypeItemForm .menuContainer {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 15px 0;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  #productsModal .modal-dialog {
    width: 65%;
    margin: 30px auto;
  }
}

.singlePageForm .productsContainer .productsList ul, .singlePageForm .formSinglePages .menuContainer .productsList ul, .formSinglePages .singlePageForm .menuContainer .productsList ul, .singlePageForm .inspirationsPageTypeItemForm .menuContainer .productsList ul, .inspirationsPageTypeItemForm .singlePageForm .menuContainer .productsList ul, .inspirationsPageTypeItemForm .productsContainer .productsList ul, .inspirationsPageTypeItemForm .menuContainer .productsList ul {
  list-style: none;
  display: inline-block;
  width: 100%;
}

.singlePageForm .productsContainer .productsList ul li, .singlePageForm .formSinglePages .menuContainer .productsList ul li, .formSinglePages .singlePageForm .menuContainer .productsList ul li, .singlePageForm .inspirationsPageTypeItemForm .menuContainer .productsList ul li, .inspirationsPageTypeItemForm .singlePageForm .menuContainer .productsList ul li, .inspirationsPageTypeItemForm .productsContainer .productsList ul li, .inspirationsPageTypeItemForm .menuContainer .productsList ul li {
  display: inline-block;
  width: 11%;
  margin: 1% 1% 1% 0;
  float: left;
  height: 250px;
  border: 1px solid #eee;
  position: relative;
}

.singlePageForm .productsContainer .productsList ul li a.removeRow, .singlePageForm .formSinglePages .menuContainer .productsList ul li a.removeRow, .formSinglePages .singlePageForm .menuContainer .productsList ul li a.removeRow, .singlePageForm .inspirationsPageTypeItemForm .menuContainer .productsList ul li a.removeRow, .inspirationsPageTypeItemForm .singlePageForm .menuContainer .productsList ul li a.removeRow, .inspirationsPageTypeItemForm .productsContainer .productsList ul li a.removeRow, .inspirationsPageTypeItemForm .menuContainer .productsList ul li a.removeRow {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
}

.singlePageForm .productsContainer .productsList ul li img, .singlePageForm .formSinglePages .menuContainer .productsList ul li img, .formSinglePages .singlePageForm .menuContainer .productsList ul li img, .singlePageForm .inspirationsPageTypeItemForm .menuContainer .productsList ul li img, .inspirationsPageTypeItemForm .singlePageForm .menuContainer .productsList ul li img, .inspirationsPageTypeItemForm .productsContainer .productsList ul li img, .inspirationsPageTypeItemForm .menuContainer .productsList ul li img {
  display: inline-block;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.singlePageForm .productsContainer .productsList ul li .imageRowContent, .singlePageForm .formSinglePages .menuContainer .productsList ul li .imageRowContent, .formSinglePages .singlePageForm .menuContainer .productsList ul li .imageRowContent, .singlePageForm .inspirationsPageTypeItemForm .menuContainer .productsList ul li .imageRowContent, .inspirationsPageTypeItemForm .singlePageForm .menuContainer .productsList ul li .imageRowContent, .inspirationsPageTypeItemForm .productsContainer .productsList ul li .imageRowContent, .inspirationsPageTypeItemForm .menuContainer .productsList ul li .imageRowContent {
  margin: 0 5px;
}

.singlePageForm .jsTree, .inspirationsPageTypeItemForm .jsTree {
  margin-top: 10px;
}

.singlePageForm .slugName span, .inspirationsPageTypeItemForm .slugName span {
  display: inline-block;
  width: auto;
  float: left;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
}

.singlePageForm .slugName input#post_name, .inspirationsPageTypeItemForm .slugName input#post_name {
  border: none;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  display: inline-block;
  width: auto;
  float: left;
  box-shadow: none;
  padding-left: 0;
  color: #0a6aa1;
  font-weight: bold;
  min-width: 400px;
}

/* jsTree default theme */
.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}

.jstree-node {
  white-space: nowrap;
}

.jstree-anchor {
  display: inline-block;
  color: black;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  margin: 0;
  vertical-align: top;
}

.jstree-anchor:focus {
  outline: 0;
}

.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
  text-decoration: none;
  color: inherit;
}

.jstree-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}

.jstree-icon:empty {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}

.jstree-ocl {
  cursor: pointer;
}

.jstree-leaf > .jstree-ocl {
  cursor: default;
}

.jstree .jstree-open > .jstree-children {
  display: block;
}

.jstree .jstree-closed > .jstree-children,
.jstree .jstree-leaf > .jstree-children {
  display: none;
}

.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}

.jstree-no-icons .jstree-themeicon,
.jstree-anchor > .jstree-themeicon-hidden {
  display: none;
}

.jstree-hidden,
.jstree-node.jstree-hidden {
  display: none;
}

.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}

.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}

.jstree-rtl .jstree-node {
  margin-left: 0;
}

.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}

.jstree-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;
}

.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}

.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
  position: relative;
}

.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.vakata-context {
  display: none;
}

.vakata-context,
.vakata-context ul {
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  box-shadow: 2px 2px 2px #999999;
}

.vakata-context ul {
  list-style: none;
  left: 100%;
  margin-top: -2.7em;
  margin-left: -4px;
}

.vakata-context .vakata-context-right ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}

.vakata-context li {
  list-style: none;
}

.vakata-context li > a {
  display: block;
  padding: 0 2em 0 2em;
  text-decoration: none;
  width: auto;
  color: black;
  white-space: nowrap;
  line-height: 2.4em;
  text-shadow: 1px 1px 0 white;
  border-radius: 1px;
}

.vakata-context li > a:hover {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: right center;
  background-repeat: no-repeat;
}

.vakata-context li > a:focus {
  outline: 0;
}

.vakata-context .vakata-context-hover > a {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}

.vakata-context .vakata-context-separator > a,
.vakata-context .vakata-context-separator > a:hover {
  background: white;
  border: 0;
  border-top: 1px solid #e2e3e3;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  padding: 0;
  margin: 0 0 0 2.4em;
  border-left: 1px solid #e0e0e0;
  text-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-radius: 0;
}

.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0;
}

.vakata-context li > a > i {
  text-decoration: none;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  background: transparent;
  margin: 0 0 0 -2em;
  vertical-align: top;
  text-align: center;
  line-height: 2.4em;
}

.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}

.vakata-context li > a .vakata-contextmenu-sep {
  display: inline-block;
  width: 1px;
  height: 2.4em;
  background: white;
  margin: 0 0.5em 0 0;
  border-left: 1px solid #e2e3e3;
}

.vakata-context .vakata-contextmenu-shortcut {
  font-size: 0.8em;
  color: silver;
  opacity: 0.5;
  display: none;
}

.vakata-context-rtl ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}

.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: left center;
  background-repeat: no-repeat;
}

.vakata-context-rtl .vakata-context-separator > a {
  margin: 0 2.4em 0 0;
  border-left: 0;
  border-right: 1px solid #e2e3e3;
}

.vakata-context-rtl .vakata-context-left ul {
  right: auto;
  left: 100%;
  margin-left: -4px;
  margin-right: auto;
}

.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}

.vakata-context-rtl li > a .vakata-contextmenu-sep {
  margin: 0 0 0 0.5em;
  border-left-color: white;
  background: #e2e3e3;
}

#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}

#jstree-dnd {
  line-height: 16px;
  margin: 0;
  padding: 4px;
}

#jstree-dnd .jstree-icon,
#jstree-dnd .jstree-copy {
  display: inline-block;
  text-decoration: none;
  margin: 0 2px 0 0;
  padding: 0;
  width: 16px;
  height: 16px;
}

#jstree-dnd .jstree-ok {
  background: green;
}

#jstree-dnd .jstree-er {
  background: red;
}

#jstree-dnd .jstree-copy {
  margin: 0 2px 0 2px;
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-repeat: no-repeat;
  background-color: transparent;
}

.jstree-default .jstree-anchor,
.jstree-default .jstree-animated,
.jstree-default .jstree-wholerow {
  transition: background-color 0.15s, box-shadow 0.15s;
}

.jstree-default .jstree-hovered {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}

.jstree-default .jstree-context {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}

.jstree-default .jstree-clicked {
  background: #beebff;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999999;
}

.jstree-default .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}

.jstree-default .jstree-disabled {
  background: transparent;
  color: #666666;
}

.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
  box-shadow: none;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default .jstree-disabled > .jstree-icon {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default .jstree-search {
  font-style: italic;
  color: #8b0000;
  font-weight: bold;
}

.jstree-default .jstree-no-checkboxes .jstree-checkbox {
  display: none !important;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked {
  background: transparent;
  box-shadow: none;
}

.jstree-default.jstree-checkbox-no-clicked .jstree-clicked.jstree-hovered {
  background: #e7f4f9;
}

.jstree-default.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked {
  background: transparent;
}

.jstree-default.jstree-checkbox-no-clicked > .jstree-wholerow-ul .jstree-wholerow-clicked.jstree-wholerow-hovered {
  background: #e7f4f9;
}

.jstree-default > .jstree-striped {
  min-width: 100%;
  display: inline-block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat;
}

.jstree-default > .jstree-wholerow-ul .jstree-hovered,
.jstree-default > .jstree-wholerow-ul .jstree-clicked {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}

.jstree-default .jstree-wholerow {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jstree-default .jstree-wholerow-hovered {
  background: #e7f4f9;
}

.jstree-default .jstree-wholerow-clicked {
  background: #beebff;
  background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
  background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%);
}

.jstree-default .jstree-node {
  min-height: 24px;
  line-height: 24px;
  margin-left: 24px;
  min-width: 24px;
}

.jstree-default .jstree-anchor {
  line-height: 24px;
  height: 24px;
}

.jstree-default .jstree-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.jstree-default .jstree-icon:empty {
  width: 24px;
  height: 24px;
  line-height: 24px;
}

.jstree-default.jstree-rtl .jstree-node {
  margin-right: 24px;
}

.jstree-default .jstree-wholerow {
  height: 24px;
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-image: url(../img/32px.png);
}

.jstree-default .jstree-node {
  background-position: -292px -4px;
  background-repeat: repeat-y;
}

.jstree-default .jstree-last {
  background: transparent;
}

.jstree-default .jstree-open > .jstree-ocl {
  background-position: -132px -4px;
}

.jstree-default .jstree-closed > .jstree-ocl {
  background-position: -100px -4px;
}

.jstree-default .jstree-leaf > .jstree-ocl {
  background-position: -68px -4px;
}

.jstree-default .jstree-themeicon {
  background-position: -260px -4px;
}

.jstree-default > .jstree-no-dots .jstree-node,
.jstree-default > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -4px;
}

.jstree-default > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -4px;
}

.jstree-default .jstree-disabled {
  background: transparent;
}

.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default .jstree-checkbox {
  background-position: -164px -4px;
}

.jstree-default .jstree-checkbox:hover {
  background-position: -164px -36px;
}

.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-default .jstree-checked > .jstree-checkbox {
  background-position: -228px -4px;
}

.jstree-default.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-default .jstree-checked > .jstree-checkbox:hover {
  background-position: -228px -36px;
}

.jstree-default .jstree-anchor > .jstree-undetermined {
  background-position: -196px -4px;
}

.jstree-default .jstree-anchor > .jstree-undetermined:hover {
  background-position: -196px -36px;
}

.jstree-default .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default > .jstree-striped {
  background-size: auto 48px;
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -132px -36px;
}

.jstree-default.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -100px -36px;
}

.jstree-default.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -68px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -36px;
}

.jstree-default.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -36px;
}

.jstree-default .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}

.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url(../img/throbber.gif) center center no-repeat;
}

.jstree-default .jstree-file {
  background: url(../img/32px.png) -100px -68px no-repeat;
}

.jstree-default .jstree-folder {
  background: url(../img/32px.png) -260px -4px no-repeat;
}

.jstree-default > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default {
  line-height: 24px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default .jstree-ok,
#jstree-dnd.jstree-default .jstree-er {
  background-image: url(../img/32px.png);
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default i {
  background: transparent;
  width: 24px;
  height: 24px;
  line-height: 24px;
}

#jstree-dnd.jstree-default .jstree-ok {
  background-position: -4px -68px;
}

#jstree-dnd.jstree-default .jstree-er {
  background-position: -36px -68px;
}

.jstree-default .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 29px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-small .jstree-node {
  min-height: 18px;
  line-height: 18px;
  margin-left: 18px;
  min-width: 18px;
}

.jstree-default-small .jstree-anchor {
  line-height: 18px;
  height: 18px;
}

.jstree-default-small .jstree-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-small .jstree-icon:empty {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  margin-right: 18px;
}

.jstree-default-small .jstree-wholerow {
  height: 18px;
}

.jstree-default-small .jstree-node,
.jstree-default-small .jstree-icon {
  background-image: url(../img/32px.png);
}

.jstree-default-small .jstree-node {
  background-position: -295px -7px;
  background-repeat: repeat-y;
}

.jstree-default-small .jstree-last {
  background: transparent;
}

.jstree-default-small .jstree-open > .jstree-ocl {
  background-position: -135px -7px;
}

.jstree-default-small .jstree-closed > .jstree-ocl {
  background-position: -103px -7px;
}

.jstree-default-small .jstree-leaf > .jstree-ocl {
  background-position: -71px -7px;
}

.jstree-default-small .jstree-themeicon {
  background-position: -263px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-node,
.jstree-default-small > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-small > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -7px;
}

.jstree-default-small > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -7px;
}

.jstree-default-small .jstree-disabled {
  background: transparent;
}

.jstree-default-small .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default-small .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-small .jstree-checkbox {
  background-position: -167px -7px;
}

.jstree-default-small .jstree-checkbox:hover {
  background-position: -167px -39px;
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-default-small .jstree-checked > .jstree-checkbox {
  background-position: -231px -7px;
}

.jstree-default-small.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-default-small .jstree-checked > .jstree-checkbox:hover {
  background-position: -231px -39px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined {
  background-position: -199px -7px;
}

.jstree-default-small .jstree-anchor > .jstree-undetermined:hover {
  background-position: -199px -39px;
}

.jstree-default-small .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default-small > .jstree-striped {
  background-size: auto 36px;
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-small.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -135px -39px;
}

.jstree-default-small.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -103px -39px;
}

.jstree-default-small.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -71px -39px;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -39px -39px;
}

.jstree-default-small.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -7px -39px;
}

.jstree-default-small .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-small > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url(../img/throbber.gif) center center no-repeat;
}

.jstree-default-small .jstree-file {
  background: url(../img/32px.png) -103px -71px no-repeat;
}

.jstree-default-small .jstree-folder {
  background: url(../img/32px.png) -263px -7px no-repeat;
}

.jstree-default-small > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-small {
  line-height: 18px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default-small .jstree-ok,
#jstree-dnd.jstree-default-small .jstree-er {
  background-image: url(../img/32px.png);
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default-small i {
  background: transparent;
  width: 18px;
  height: 18px;
  line-height: 18px;
}

#jstree-dnd.jstree-default-small .jstree-ok {
  background-position: -7px -71px;
}

#jstree-dnd.jstree-default-small .jstree-er {
  background-position: -39px -71px;
}

.jstree-default-small .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-small .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 23px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-small .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default-small.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAACAQMAAABv1h6PAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMHBgAAiABBI4gz9AAAAABJRU5ErkJggg==");
}

.jstree-default-small.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-large .jstree-node {
  min-height: 32px;
  line-height: 32px;
  margin-left: 32px;
  min-width: 32px;
}

.jstree-default-large .jstree-anchor {
  line-height: 32px;
  height: 32px;
}

.jstree-default-large .jstree-icon {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-large .jstree-icon:empty {
  width: 32px;
  height: 32px;
  line-height: 32px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  margin-right: 32px;
}

.jstree-default-large .jstree-wholerow {
  height: 32px;
}

.jstree-default-large .jstree-node,
.jstree-default-large .jstree-icon {
  background-image: url(../img/32px.png);
}

.jstree-default-large .jstree-node {
  background-position: -288px 0px;
  background-repeat: repeat-y;
}

.jstree-default-large .jstree-last {
  background: transparent;
}

.jstree-default-large .jstree-open > .jstree-ocl {
  background-position: -128px 0px;
}

.jstree-default-large .jstree-closed > .jstree-ocl {
  background-position: -96px 0px;
}

.jstree-default-large .jstree-leaf > .jstree-ocl {
  background-position: -64px 0px;
}

.jstree-default-large .jstree-themeicon {
  background-position: -256px 0px;
}

.jstree-default-large > .jstree-no-dots .jstree-node,
.jstree-default-large > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-large > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px 0px;
}

.jstree-default-large > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0px 0px;
}

.jstree-default-large .jstree-disabled {
  background: transparent;
}

.jstree-default-large .jstree-disabled.jstree-hovered {
  background: transparent;
}

.jstree-default-large .jstree-disabled.jstree-clicked {
  background: #efefef;
}

.jstree-default-large .jstree-checkbox {
  background-position: -160px 0px;
}

.jstree-default-large .jstree-checkbox:hover {
  background-position: -160px -32px;
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
.jstree-default-large .jstree-checked > .jstree-checkbox {
  background-position: -224px 0px;
}

.jstree-default-large.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
.jstree-default-large .jstree-checked > .jstree-checkbox:hover {
  background-position: -224px -32px;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined {
  background-position: -192px 0px;
}

.jstree-default-large .jstree-anchor > .jstree-undetermined:hover {
  background-position: -192px -32px;
}

.jstree-default-large .jstree-checkbox-disabled {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='jstree-grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}

.jstree-default-large > .jstree-striped {
  background-size: auto 64px;
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAACAQMAAAB49I5GAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjAAMOBgAAGAAJMwQHdQAAAABJRU5ErkJggg==");
  background-position: 100% 1px;
  background-repeat: repeat-y;
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: transparent;
}

.jstree-default-large.jstree-rtl .jstree-open > .jstree-ocl {
  background-position: -128px -32px;
}

.jstree-default-large.jstree-rtl .jstree-closed > .jstree-ocl {
  background-position: -96px -32px;
}

.jstree-default-large.jstree-rtl .jstree-leaf > .jstree-ocl {
  background-position: -64px -32px;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-node,
.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -32px -32px;
}

.jstree-default-large.jstree-rtl > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: 0px -32px;
}

.jstree-default-large .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}

.jstree-default-large > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url(../img/throbber.gif) center center no-repeat;
}

.jstree-default-large .jstree-file {
  background: url(../img/32px.png) -96px -64px no-repeat;
}

.jstree-default-large .jstree-folder {
  background: url(../img/32px.png) -256px 0px no-repeat;
}

.jstree-default-large > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

#jstree-dnd.jstree-default-large {
  line-height: 32px;
  padding: 0 4px;
}

#jstree-dnd.jstree-default-large .jstree-ok,
#jstree-dnd.jstree-default-large .jstree-er {
  background-image: url(../img/32px.png);
  background-repeat: no-repeat;
  background-color: transparent;
}

#jstree-dnd.jstree-default-large i {
  background: transparent;
  width: 32px;
  height: 32px;
  line-height: 32px;
}

#jstree-dnd.jstree-default-large .jstree-ok {
  background-position: 0px -64px;
}

#jstree-dnd.jstree-default-large .jstree-er {
  background-position: -32px -64px;
}

.jstree-default-large .jstree-ellipsis {
  overflow: hidden;
}

.jstree-default-large .jstree-ellipsis .jstree-anchor {
  width: calc(100% - 37px);
  text-overflow: ellipsis;
  overflow: hidden;
}

.jstree-default-large .jstree-ellipsis.jstree-no-icons .jstree-anchor {
  width: calc(100% - 5px);
}

.jstree-default-large.jstree-rtl .jstree-node {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAACAQMAAAAD0EyKAAAABlBMVEUAAAAdHRvEkCwcAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjgIIGBgABCgCBvVLXcAAAAABJRU5ErkJggg==");
}

.jstree-default-large.jstree-rtl .jstree-last {
  background: transparent;
}

@media (max-width: 768px) {
  #jstree-dnd.jstree-dnd-responsive {
    line-height: 40px;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }
  #jstree-dnd.jstree-dnd-responsive > i {
    background: transparent;
    width: 40px;
    height: 40px;
  }
  #jstree-dnd.jstree-dnd-responsive > .jstree-ok {
    background-image: url(../img/32px.png);
    background-position: 0 -200px;
    background-size: 120px 240px;
  }
  #jstree-dnd.jstree-dnd-responsive > .jstree-er {
    background-image: url(../img/32px.png);
    background-position: -40px -200px;
    background-size: 120px 240px;
  }
  #jstree-marker.jstree-dnd-responsive {
    border-left-width: 10px;
    border-top-width: 10px;
    border-bottom-width: 10px;
    margin-top: -10px;
  }
}

@media (max-width: 768px) {
  .jstree-default-responsive {
    /*
	.jstree-open > .jstree-ocl,
	.jstree-closed > .jstree-ocl { border-radius:20px; background-color:white; }
	*/
  }
  .jstree-default-responsive .jstree-icon {
    background-image: url(../img/32px.png);
  }
  .jstree-default-responsive .jstree-node,
  .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background: transparent;
  }
  .jstree-default-responsive .jstree-node {
    min-height: 40px;
    line-height: 40px;
    margin-left: 40px;
    min-width: 40px;
    white-space: nowrap;
  }
  .jstree-default-responsive .jstree-anchor {
    line-height: 40px;
    height: 40px;
  }
  .jstree-default-responsive .jstree-icon,
  .jstree-default-responsive .jstree-icon:empty {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
  }
  .jstree-default-responsive.jstree-rtl .jstree-node {
    margin-left: 0;
    margin-right: 40px;
    background: transparent;
  }
  .jstree-default-responsive.jstree-rtl .jstree-container-ul > .jstree-node {
    margin-right: 0;
  }
  .jstree-default-responsive .jstree-ocl,
  .jstree-default-responsive .jstree-themeicon,
  .jstree-default-responsive .jstree-checkbox {
    background-size: 120px 240px;
  }
  .jstree-default-responsive .jstree-leaf > .jstree-ocl,
  .jstree-default-responsive.jstree-rtl .jstree-leaf > .jstree-ocl {
    background: transparent;
  }
  .jstree-default-responsive .jstree-open > .jstree-ocl {
    background-position: 0 0px !important;
  }
  .jstree-default-responsive .jstree-closed > .jstree-ocl {
    background-position: 0 -40px !important;
  }
  .jstree-default-responsive.jstree-rtl .jstree-closed > .jstree-ocl {
    background-position: -40px 0px !important;
  }
  .jstree-default-responsive .jstree-themeicon {
    background-position: -40px -40px;
  }
  .jstree-default-responsive .jstree-checkbox,
  .jstree-default-responsive .jstree-checkbox:hover {
    background-position: -40px -80px;
  }
  .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox,
  .jstree-default-responsive.jstree-checkbox-selection .jstree-clicked > .jstree-checkbox:hover,
  .jstree-default-responsive .jstree-checked > .jstree-checkbox,
  .jstree-default-responsive .jstree-checked > .jstree-checkbox:hover {
    background-position: 0 -80px;
  }
  .jstree-default-responsive .jstree-anchor > .jstree-undetermined,
  .jstree-default-responsive .jstree-anchor > .jstree-undetermined:hover {
    background-position: 0 -120px;
  }
  .jstree-default-responsive .jstree-anchor {
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 1px 1px white;
  }
  .jstree-default-responsive > .jstree-striped {
    background: transparent;
  }
  .jstree-default-responsive .jstree-wholerow {
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    border-bottom: 1px solid rgba(64, 64, 64, 0.2);
    background: #ebebeb;
    height: 40px;
  }
  .jstree-default-responsive .jstree-wholerow-hovered {
    background: #e7f4f9;
  }
  .jstree-default-responsive .jstree-wholerow-clicked {
    background: #beebff;
  }
  .jstree-default-responsive .jstree-children .jstree-last > .jstree-wholerow {
    box-shadow: inset 0 -6px 3px -5px #666666;
  }
  .jstree-default-responsive .jstree-children .jstree-open > .jstree-wholerow {
    box-shadow: inset 0 6px 3px -5px #666666;
    border-top: 0;
  }
  .jstree-default-responsive .jstree-children .jstree-open + .jstree-open {
    box-shadow: none;
  }
  .jstree-default-responsive .jstree-node,
  .jstree-default-responsive .jstree-icon,
  .jstree-default-responsive .jstree-node > .jstree-ocl,
  .jstree-default-responsive .jstree-themeicon,
  .jstree-default-responsive .jstree-checkbox {
    background-image: url(../img/32px.png);
    background-size: 120px 240px;
  }
  .jstree-default-responsive .jstree-node {
    background-position: -80px 0;
    background-repeat: repeat-y;
  }
  .jstree-default-responsive .jstree-last {
    background: transparent;
  }
  .jstree-default-responsive .jstree-leaf > .jstree-ocl {
    background-position: -40px -120px;
  }
  .jstree-default-responsive .jstree-last > .jstree-ocl {
    background-position: -40px -160px;
  }
  .jstree-default-responsive .jstree-themeicon-custom {
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
  }
  .jstree-default-responsive .jstree-file {
    background: url(../img/32px.png) 0 -160px no-repeat;
    background-size: 120px 240px;
  }
  .jstree-default-responsive .jstree-folder {
    background: url(../img/32px.png) -40px -40px no-repeat;
    background-size: 120px 240px;
  }
  .jstree-default-responsive > .jstree-container-ul > .jstree-node {
    margin-left: 0;
    margin-right: 0;
  }
}

.jstree-default .jstree-anchor {
  word-wrap: break-word;
  width: 95%;
  word-break: break-all;
  display: inline-block;
  white-space: normal;
  height: auto;
}

.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}

.ui-timepicker-div dl {
  text-align: left;
}

.ui-timepicker-div dl dt {
  float: left;
  clear: left;
  padding: 0 0 0 5px;
}

.ui-timepicker-div dl dd {
  margin: 0 10px 10px 40%;
}

.ui-timepicker-div td {
  font-size: 90%;
}

.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ui-timepicker-div .ui_tpicker_unit_hide {
  display: none;
}

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input {
  background: none;
  color: inherit;
  border: none;
  outline: none;
  border-bottom: solid 1px #555;
  width: 95%;
}

.ui-timepicker-div .ui_tpicker_time .ui_tpicker_time_input:focus {
  border-bottom-color: #aaa;
}

.ui-timepicker-rtl {
  direction: rtl;
}

.ui-timepicker-rtl dl {
  text-align: right;
  padding: 0 5px 0 0;
}

.ui-timepicker-rtl dl dt {
  float: right;
  clear: right;
}

.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}

/* Shortened version style */
.ui-timepicker-div.ui-timepicker-oneLine {
  padding-right: 2px;
}

.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time,
.ui-timepicker-div.ui-timepicker-oneLine dt {
  display: none;
}

.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_time_label {
  display: block;
  padding-top: 2px;
}

.ui-timepicker-div.ui-timepicker-oneLine dl {
  text-align: right;
}

.ui-timepicker-div.ui-timepicker-oneLine dl dd,
.ui-timepicker-div.ui-timepicker-oneLine dl dd > div {
  display: inline-block;
  margin: 0;
}

.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_minute:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_second:before {
  content: ':';
  display: inline-block;
}

.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_millisec:before,
.ui-timepicker-div.ui-timepicker-oneLine dl dd.ui_tpicker_microsec:before {
  content: '.';
  display: inline-block;
}

.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide,
.ui-timepicker-div.ui-timepicker-oneLine .ui_tpicker_unit_hide:before {
  display: none;
}

#inspirations .slugName span {
  display: inline-block;
  width: auto;
  float: left;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
}

#inspirations .slugName input#post_name {
  border: none;
  font-size: 12px;
  line-height: 20px;
  height: 18px;
  display: inline-block;
  width: auto;
  float: left;
  box-shadow: none;
  padding-left: 0;
  color: #0a6aa1;
  font-weight: bold;
  min-width: 400px;
}

#inspirations .inspirationsPageForm .form-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

#inspirations a.showImage {
  display: inline-block;
  position: relative;
}

#inspirations a.showImage span.removeImage {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 25px;
  color: #7a0026;
  cursor: pointer;
}

.navbar-nav > li > .dropdown-menu {
  width: 100%;
  background-color: #222;
}
