// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";
// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

body {
  padding-bottom: 15px;
}

.page-header {
  @include clearfix;

  aside {
    float: right;
  }
}

.btn-group {
  padding-bottom: 25px;
}

.form-inline {
  .form-group {
    height: 60px;
  }
}

.table {
  tr.summary {
    td {
      border-top: 0;
    }
  }

}

.search-orders {
  padding-bottom: 25px;
}

body.not-logged-in {
  padding: 0;
  margin: 0;
  background-color: #fff;
}

@import "products";
@import "categories";
@import "orders";
@import "customers";
@import "sales_reps";
@import "reviews";
@import "faq";
@import "designers";
@import "designers_posts";
@import "single_pages";
@import "jstree";
@import "jquery-ui-timepicker-addon";
@import "inspirations";

.navbar-nav > li > .dropdown-menu {
  width: 100%;
  background-color: #222;
}


