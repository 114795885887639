.table-products {
  th {
    //white-space: nowrap;
    text-align: center;
  }
  tr {
    td {
      vertical-align: middle; // bad: it's broken by some rule
    }

    .id { font-weight: bold; text-align: right; }
    .sku { font-weight: bold; }
    .stock { text-align: center; }
    .stock-instock { color: darkgreen; }
    .stock-nostock { color: darkred; }
    .price {
      text-align: right;
      * { white-space: nowrap; }
      *:before { content: '$'; }
      del { color: darkred; }
      ins { color: darkgreen; text-decoration: none; }
    }
    .post_date { text-align: center; }
    .actions { width: 11em; }
    .quantity { text-align: center; }
  }

  .form-group {
    position: relative;
    display: inline-block;
    width: 100%;
  }
}


.product-details {
  .product-img { margin-bottom: 10px; }
}

label.required:after{
  content: ' *';
  color: #880000;
  font-weight: bold;
}

.formHideFix {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100px;
  height: 30px !important;
  padding: 0;
  margin: 0;
}

textarea.tinymce {
  display: inline !important;
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
}

.marginTop10 {
  margin-top: 10px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

#attributesRows {
  margin-top: 15px;

  .select2-container {
    width: 100% !important;
  }
}

.red {
  color: #880000;
}

#attributesRows .form-group {
  display: inline-block;
  width: 100%;
}

div[data-attribute-id] {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

#variationsRows {
  margin-top: 15px;
}

.marginLeftNone {
  margin-left: 0!important;
}

.tableVariations {
    display: inline-block;
    max-height: 450px !important;
    overflow: auto;
    width: 100%;
}

.searchForm {
  .form-group {
    height: auto;
    position: relative;

    @media (min-width: 768px) {
      width: 32%;
      @media (min-width: 1100px) { width: 16%; }
      input, select, span.select2-container {
        display: block;
        width: 100% !important; // !important to override select2 inline style
      }
    }

    .clearInput {
      position: absolute;
      top: 8px;
      right: 5px;
      color: #880000;
    }

    .clearSelect {
      position: absolute;
      top: 2px;
      right: 5px;
      color: #880000;
    }
  }
}

.imageContainer {
  position: relative;
  display: inline-block;
  width: auto;
  margin-top: 10px;

  img {
    display: inline-block;
    max-height: 240px;
  }

  &:first-child {
    img {
      background-color: #faebcc;
      border-color: #e3d7c1;
    }
  }

  .removeImage {
    display: inline-block;
    color: #ccc;
    position: absolute;
    top: 0px;
    right: 5px;

    &:hover {
      color: #fff;
    }
  }
}

.inline {
  display: inline-block !important;
}

.marginNone {
  margin: 0 !important;
}

.paddingLeftNone {
  padding-left: 0 !important;
}

.tab-content > .tab-pane {
  display: block;
  opacity: 0;
  position: absolute;
  left: -100000px;
}

.tab-content > .active {
  opacity: 1;
  display: block;
  position: relative;
  left: 0;
}

.select2-container {
  .select2-selection--multiple, .select2-selection--single {
    min-height: 32px;
    border-color: #ccc;
  }
}

.form-group {
  //margin-bottom: 10px !important;
}

.width100 {
  display: inline-block !important;
  width: 100% !important;
}


