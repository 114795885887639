.table-sales-reps {
  th {
    text-align: left;
  }
  .row-product {
    td { vertical-align: middle; }

    .id { font-weight: bold; text-align: left; }
    .zip { font-weight: bold; }
    .rep_code { text-align: left; }
    .rep_account { color: darkgreen; }
    .created_at, .updated_at { text-align: left; }
    .actions { width: 8em; }
  }
}
