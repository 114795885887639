.table-faq {

  tbody {
    tr {
      &:first-of-type{
        td {
          border: none !important;
        }
      }
      td, th {
        &.actions {
          word-wrap: break-word;
        }
        &.comment {
          word-wrap: break-word;
          width: 55%;
        }
      }
    }
  }
}