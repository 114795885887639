.table-designers_posts {
  .row-designers_posts {
    &.bg-danger {
      background-color: #f2dede !important;
    }
  }
}

.formDesignerPost {
  .form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
}
