.table-designers {
  .row-designers {
    &.bg-danger {
      background-color: #f2dede !important;
    }
  }
}

.formDesigner {
  .form-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
}
