.table-categories {
  @extend .table-products;
  th {
    text-align: left;
    &.thumb {
      min-width: 50px;
      @media (min-width: 520px) { min-width: 100px; }
    }
    &.text { width: 90%; }
    &.actions { min-width: 10em; }
  }
  tr {
    td {
      &.id { }
      &.thumb {
        text-align: center;
        img {
          display: inline;
          max-height: 33px;
          @media (min-width: 520px) { max-height: 66px; }
        }
      }
      &.text { }
      &.actions { }
    }

    &.depth-0 {
      td.text { font-weight: bold; font-size: 120%; }
    }
    &.depth-1 {
      td.text { }
    }
    &:not(.depth-0):not(.depth-1) {
      td.text { font-style: italic; }
    }
  }
}
